import React from "react"
import { Link, graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer"
import slugify from "slugify"
import image from "../../content/assets/bg-categorylist-pro.jpg"

const CategoryList = ({
  pageContext: { category },
  data: { allMarkdownRemark, imgPro, imgPart, allFile },
  location,
}) => {
  let uniqueServiceData = [
    ...allMarkdownRemark.edges
      .reduce(
        (map, obj) => map.set(obj.node.frontmatter.service, obj),
        new Map()
      )
      .values(),
  ]
  const formatCover = image => {
    return (
      allFile &&
      image &&
      allFile.edges.filter(e => e.node.name === image)[0].node.publicURL
    )
  }
  uniqueServiceData = uniqueServiceData.map(f => {
    return {
      node: {
        frontmatter: {
          categories: f.node.frontmatter.categories,
          date: f.node.frontmatter.date,
          imageCover: f.node.frontmatter.imageCover
            ? formatCover(f.node.frontmatter.imageCover)
            : null,
          service: f.node.frontmatter.service,
          title: f.node.frontmatter.title,
          serviceCover: f.node.frontmatter.serviceCover
            ? formatCover(f.node.frontmatter.serviceCover)
            : null,
        },
        id: f.node.id,
      },
    }
  })
  console.log("toto", uniqueServiceData)
  if (typeof window !== "undefined") {
    localStorage.setItem("uniqueServiceData", JSON.stringify(uniqueServiceData))
  }
  return (
    <div>
      <Header location={location} />

      <div className="relative w-full h-[200px] md:h-[350px] md:grid md:grid-cols-2">
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10 md:relative md:bg-[#302e28] md:h-full md:items-center md:justify-center">
          <h1 className="text-white text-2xl tracking-tight font-extrabold sm:text-5xl text-center md:text-5xl md:text-center">
            Découvrir mes activités
          </h1>
        </div>
        <img
          src={image}
          className="object-cover w-full h-[200px] md:h-[350px] md:col-span-1"
          style={{ height: "h-[200px] md:h-[350px]" }} // Ajout de style pour définir la taille de l'image
          alt={"image" + category}
        />
      </div>

      <div className="bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 p-4 h-80 sm:h-72">
        {uniqueServiceData.map(({ node }) => {
          return (
            <div key={node.id}>
              <Link
                to={`service/${slugify(
                  node.frontmatter.service.toLowerCase()
                )}`}
              >
                <div
                  style={{
                    background: node.frontmatter.serviceCover
                      ? "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url(" +
                        node.frontmatter.serviceCover +
                        ")"
                      : "bg-blue-900",
                    background: node.frontmatter.serviceCover
                      ? "-webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),url(" +
                        node.frontmatter.serviceCover +
                        ")"
                      : "bg-blue-900",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="bg-blue-900 rounded-xl h-full flex justify-center items-center text-white text-xl font-bold"
                >
                  <h2 className="uppercase">{node.frontmatter.service}</h2>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query CategoryListQuery($ids: [String]!) {
    allMarkdownRemark(filter: { id: { in: $ids } }) {
      edges {
        node {
          frontmatter {
            imageCover
            title
            service
            categories
            serviceCover
            date(formatString: "MMM D, YYYY")
          }
          id
        }
      }
    }
    imgPro: file(relativePath: { eq: "assets/bg-categorylist-pro.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgPart: file(relativePath: { eq: "assets/bg-categorylist-part.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`

export default CategoryList
